import React, { Suspense, lazy } from 'react';
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from 'components/layout'
const VPSadmin = lazy(() => import('sections/hero/VPSadmin.js'));
const ServicesAdmin = lazy(() => import('sections/services/ServicesAdmin.js'));
const BotonCloud = lazy(() => import('sections/services/BotonCloud.js'));
const BoxCol2Admin = lazy(() => import('sections/hero/BoxCol2Admin.js'));
const VPSadminPremium = lazy(() => import('sections/services/VPSadminPremium.js'));
const Llamenos = lazy(() => import('sections/hero/Llamenos.js'));
const ContactCreative = lazy(() => import('sections/contact/ContactCreative.js'));
const Footer = lazy(() => import('sections/services/Footer.js'));

class Index extends React.Component {

  render() {



    return (
      <div>
        <Helmet>
          <title>VPS administrado: Soluciones de servidores 100% gestionado</title>
          <meta name="description" content="Descubre la excelencia de VPS administrado en Colombia. Servidores 100% gestionados para una presencia en línea segura y eficiente. ¡Optimiza tu hosting con nosotros!" />
        </Helmet>
        <Layout>
          <Suspense fallback={<div>Cargando...</div>}>
            <VPSadmin />
            <VPSadminPremium />
            <ServicesAdmin />
            <BoxCol2Admin />
            <Llamenos />
            <BotonCloud />
            <ContactCreative />
            <Footer />
          </Suspense>
        </Layout>
      </div>
    )
  }
}

export default Index

export const creativeTwoQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
  }
`